import Modal from "react-bootstrap/Modal";

function StaticBackDropModal({ isShow, title, body }) {
  return (
    <>
      <Modal show={isShow} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </>
  );
}

export default StaticBackDropModal;
