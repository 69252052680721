import { useState, useEffect } from "react";
import { socket } from "./socket";
import AuthenInfoInput from "./components/AuthenInfoInput";
import QRCodeGenerator from "./components/QRCodeGenerator";
import StaticBackDropModal from "./components/StaticBackDropModal";
import DownloadAppTrainingQRs from "./components/DownloadAppTrainingQRs";

function App() {
  const [outletToken, setOutletToken] = useState("");
  const [allowClient, setAllowClient] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Có lỗi khi kết nối đến máy chủ. Hãy load lại trang/liên hệ team TECH để giải quyết vấn đề!"
  );

  useEffect(() => {
    document.title = "Scan-qr-to-login";
    setOutletToken(localStorage.getItem("outletToken"));
  }, []);

  useEffect(() => {
    const onConnect = () => {
      setAllowClient(true);
      console.log("Connected to server.");
    };

    const onDisconnect = () => {
      console.log("Disconnected from server.");
    };

    const onConnectError = (err) => {
      if (err.message === "outlet_client_socket_existed") {
        setErrorMessage(
          "Ứng dụng này đã được mở ở tab khác. Hãy đóng tab này và quay trở lại tab ứng dụng ban đầu."
        );
        return console.log("Outlet Client Existed!");
      }
      console.log("Can't connect to server");
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onConnectError);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onConnectError);
    };
  }, []);

  return (
    <>
      {!outletToken && (
        <>
          <AuthenInfoInput />
          <DownloadAppTrainingQRs />
        </>
      )}
      {outletToken && allowClient && (
        <>
          <QRCodeGenerator />
          <DownloadAppTrainingQRs />
        </>
      )}
      <StaticBackDropModal
        isShow={!allowClient}
        title={"CẢNH BÁO"}
        body={errorMessage}
      ></StaticBackDropModal>
    </>
  );
}

export default App;
