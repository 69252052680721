import { useState } from "react";

import { Button, Container, Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthenInfoInput = () => {
  const [outletToken, setOutletToken] = useState("");

  const saveAuthenInfo = (event) => {
    event.preventDefault();
    if (!outletToken) {
      toast.error("Bạn chưa nhập token !", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
      return;
    }
    localStorage.setItem("outletToken", outletToken);
    window.location.reload();
  };

  return (
    <>
      <Container className="mh-5 mb-3 w-50">
        <h1 className="text-center mb-5">Nhập token để tạo mã đăng nhập</h1>
        <Form>
          <Form.Group className="d-flex align-items-center">
            <Form.Control
              onChange={(e) => setOutletToken(e.target.value)}
              value={outletToken}
              type="password"
              placeholder="Nhập token"
            ></Form.Control>
            <Button
              variant="primary"
              type="submit"
              className="ms-3"
              onClick={saveAuthenInfo}
            >
              Lưu
            </Button>
          </Form.Group>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AuthenInfoInput;
