import common from "../common";
import { socket } from "../socket";
import QRCode from "qrcode";
import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Image, ProgressBar } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const TOKEN_EXIST_TIME = 20;

function QRCodeGenerator() {
  const [qrCodeURL, setQrCodeURL] = useState("");
  const [secondsLeft, setSecondsLeft] = useState(TOKEN_EXIST_TIME);
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    const onRequestNewTokenEvent = () => {
      setFlag(Math.random());
    };

    socket.on("requestNewToken", onRequestNewTokenEvent);

    return () => {
      socket.off("requestNewToken", onRequestNewTokenEvent);
    };
  }, []);

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, TOKEN_EXIST_TIME * 1000);

    return () => clearInterval(interval);
  }, [flag]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const url = await generateQRCodeURL();
      setQrCodeURL(url);
      setSecondsLeft(TOKEN_EXIST_TIME);
      toast.success("Mã đăng nhập vừa mới được tạo mới!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(
        "Đã gặp phải lỗi trong quá trình tạo mã. Hãy load lại trang/liên hệ team TECH để giải quyết vấn đề!",
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        }
      );
    }
  };

  const generateQRCodeURL = async () => {
    const outletToken = localStorage.getItem("outletToken");
    const url = `${common.getWebworkDomain()}/qr-code-token/get-token`;
    const { data } = await axios.get(url, {
      params: {
        outletToken,
      },
    });
    let qrUrl = "";
    await QRCode.toDataURL(
      data.token,
      {
        width: 400,
        margin: 2,
      },
      (err, url) => {
        if (err) return console.log(err);
        qrUrl = url;
      }
    );
    return qrUrl;
  };

  const percentage = (secondsLeft / TOKEN_EXIST_TIME) * 100;

  return (
    <>
      <Container className="mh-5 mb-3 text-center">
        <h1 className="mb-3">Quét mã QR để đăng nhập</h1>
        <h3 className="mb-3">
          Trường hợp không đăng nhập được với mã QR hiển thị trên màn hình vui
          lòng liên hệ với team Tech qua Mattermost hoặc Zalo theo SĐT
          0328590515
        </h3>
        <Container className="w-50">
          <Image
            className="mb-3"
            alt="Mã QR đăng nhập"
            src={qrCodeURL}
            thumbnail
          />
          <ProgressBar
            className="mh-5"
            now={percentage}
            label={`${secondsLeft}s`}
          />
        </Container>
      </Container>
      <ToastContainer />
    </>
  );
}

export default QRCodeGenerator;
