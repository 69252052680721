import { io } from "socket.io-client";
import common from "./common";

const URL = common.getDomain();

export const socket = io(URL, {
  transports: ["websocket"],
  auth: (cb) => {
    cb({ outletToken: localStorage.outletToken });
  },
});
