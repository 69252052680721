import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const DownloadAppTrainingQRs = () => {
  const [androidQRURL, setAndroidQRURL] = useState("");
  const [iosQRURL, setIosQRURL] = useState("");

  const generateQRCodeURL = async (link) => {
    try {
      const qrUrl = await QRCode.toDataURL(link, {
        width: 100,
        margin: 2,
      });
      return qrUrl;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const androidLink =
      "https://play.google.com/store/apps/details?id=vn.abby.internal.staff";
    const iosLink = "https://apps.apple.com/us/app/4handy-mobile/id1617205661";
    setAndroidQRURL(await generateQRCodeURL(androidLink));
    setIosQRURL(await generateQRCodeURL(iosLink));
  };

  return (
    <Container className="mb-3 text-center">
      <h3>Tải app training tại đây</h3>
      <Container className="d-flex justify-content-center">
        <div className="text-center m-2">
          <h4>Android</h4>
          {androidQRURL && (
            <Image
              alt="Mã QR tải app nhân viên Android"
              src={androidQRURL}
              thumbnail
            />
          )}
        </div>
        <div className="text-center m-2">
          <h4>iOS</h4>
          {iosQRURL && (
            <Image alt="Mã QR tải app nhân viên iOS" src={iosQRURL} thumbnail />
          )}
        </div>
      </Container>
    </Container>
  );
};

export default DownloadAppTrainingQRs;
